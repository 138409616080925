import { wrap } from "@popmotion/popcorn";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";

let interval;

const Marquee = ({ words = [], theme = "dark" }) => {
  const [index, setCurrentIndex] = useState(0);

  const currentWord = words[index];

  useEffect(() => {
    setCurrentIndex(0);

    const nextIndex = () => {
      setCurrentIndex((old) => wrap(0, words.length, old + 1));
    };

    if (words.length > 1) {
      interval = setInterval(nextIndex, 3000);
    } else {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [words.length]);

  return (
    <div
      className={clsx(
        "Marquee",
        "max-w-3xl",
        "text-4xl lg:text-5xl xl:text-7xl",
        "font-bold leading-none tracking-tight",
        "relative",
        { "text-white": theme === "light" }
      )}
    >
      is{" "}
      <div
        className={clsx(
          "Marquee_wordContainer",
          "absolute left-0 top-0",
          "w-[375px] md:w-[475px]  xl:w-[768px]",
          "indent-sm md:indent-md lg:indent-lg xl:indent-xl"
        )}
      >
        <AnimatePresence exitBeforeEnter>
          <motion.div
            key={`${currentWord}-${index}`}
            initial={{ y: 8, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -8, opacity: 0 }}
            transition={{
              ease: [0, 0, 0.2, 1],
              duration: 0.5,
            }}
            className={clsx("Marquee__word", "block", "pr-12 lg:pr-0")}
          >
            {currentWord}
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
};

export default Marquee;
