"use client";

import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

type HeaderContextType = {
  isPinned: boolean;
  isTop: boolean;
};

const initialContextValue: HeaderContextType = {
  isPinned: true,
  isTop: true,
};

export const HeaderContext =
  createContext<HeaderContextType>(initialContextValue);

type HeaderProviderProps = {
  children: ReactNode;
};

export const HeaderProvider = ({ children }: HeaderProviderProps) => {
  const prevScrollPosRef = useRef(0);
  const [isPinned, setIsPinned] = useState(true);
  const [isTop, setIsTop] = useState(true);

  const handleScroll = useCallback(() => {
    const currentScrollPos = window.pageYOffset;

    // If we're at the top of the page
    if (currentScrollPos <= 0) {
      if (!isPinned) {
        setIsPinned(true);
      }
      if (!isTop) {
        setIsTop(true); // Set isTop to true if we're at the top of the page
      }
    } else {
      if (isTop) {
        setIsTop(false); // Set isTop to false if we're not at the top
      }
    }

    // Determine scroll direction
    const scrollingUp = prevScrollPosRef.current > currentScrollPos;

    // Only update visibility if we're not at the top of the page
    if (currentScrollPos > 0) {
      // If there's a change in the scrolling direction, update the visibility.
      if (scrollingUp && !isPinned) {
        setIsPinned(true);
      } else if (!scrollingUp && isPinned) {
        setIsPinned(false);
      }
    }

    prevScrollPosRef.current = currentScrollPos;
  }, [isPinned, isTop]);

  useEffect(() => {
    // Ensure we're in a browser environment
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [handleScroll]);

  return (
    <HeaderContext.Provider value={{ isPinned, isTop }}>
      {children}
    </HeaderContext.Provider>
  );
};

export const useHeaderContext = (): HeaderContextType => {
  const context = useContext(HeaderContext);

  if (context === undefined) {
    throw new Error("useHeaderContext must be used within a HeaderProvider");
  }

  return context;
};
