import LinkedInIcon from "@/components/LinkedInIcon";
import Logotype from "@/components/Logotype";
import TwitterIcon from "@/components/TwitterIcon";
import { NavigationItem } from "@/interfaces/NavigationItem.interface";
import getHref from "@/lib/routes";
import clsx from "clsx";
import Link from "next/link";
import { useRouter } from "next/router";

interface IProps {
  primaryNavigation: NavigationItem[];
  footerNavigation: NavigationItem[];
}

const SiteFooter = ({
  primaryNavigation,
  footerNavigation,
}: IProps): JSX.Element => {
  const d = new Date();
  const year = d.getFullYear();

  const router = useRouter();

  const _NavigationItems = () => {
    return (
      <div
        className={clsx(
          "SiteFooter__navigationItems",
          "flex flex-col",
          "space-y-1",
        )}
      >
        {primaryNavigation.map((item) => {
          if (item.level !== 1) return;

          const href = item.element ? getHref(item.element) : item.url;

          const target = !item.element ? "_blank" : null;

          const isActive = href === router.asPath;

          return (
            <Link
              key={item.id}
              href={href}
              className={clsx(
                "SiteFooter__navigationItem",
                "text-4xl font-bold tracking-tight",
                "transition-opacity",
                "hover:opacity-60",
              )}
              target={target}
            >
              {item.title}
            </Link>
          );
        })}
        <a
          href="https://www.sportcast.com.au"
          className={clsx(
            "SiteFooter__navigationItem",
            "text-4xl font-bold tracking-tight",
            "transition-opacity",
            "hover:opacity-60",
          )}
          target="_blank"
        >
          Sportcast
        </a>
        <a
          href="https://www.neccton.com"
          className={clsx(
            "SiteFooter__navigationItem",
            "text-4xl font-bold tracking-tight",
            "transition-opacity",
            "hover:opacity-60",
          )}
          target="_blank"
        >
          Neccton
        </a>
      </div>
    );
  };

  const _FooterNavigationItems = () => {
    return (
      <div
        className={clsx(
          "SiteFooter__footerNavigationItems",
          "flex flex-col",
          "space-y-1",
          "mb-8",
        )}
      >
        {footerNavigation.map((item) => {
          const href = item.element ? getHref(item.element) : item.url;

          const target = !item.element ? "_blank" : null;

          const isActive = href === router.asPath;

          return (
            <Link
              key={item.id}
              href={href}
              className={clsx(
                "SiteFooter__navigationItem",
                "text-xl font-bold tracking-tight transition-opacity hover:opacity-60",
              )}
              target={target}
            >
              {item.title}
            </Link>
          );
        })}
      </div>
    );
  };

  const _SocialItem = ({ href, icon, label = "" }) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noreferrer"
        className={clsx(
          "PrimaryNavigation__item--social",
          "flex items-center justify-center",
          "h-6 w-6",
          "transition-opacity",
          "hover:opacity-60",
        )}
        aria-label={label}
      >
        {icon}
      </a>
    );
  };

  return (
    <footer
      className={clsx(
        "SiteFooter",
        "relative",
        "w-full",
        "bg-gradient-to-br from-navy via-navy to-blue",
        "text-white",
      )}
    >
      <div
        className={clsx(
          "SiteFooter__content",
          "mx-auto max-w-screen-3xl",
          "flex flex-col lg:flex-row",
          "lg:px-8",
        )}
      >
        <div
          className={clsx(
            "SiteFooter__logotypeWrapper",
            "lg:w-3/12",
            "px-8 py-16",
          )}
        >
          <div className={clsx("SiteFooter__logotype", "w-36")}>
            <Logotype />
          </div>
        </div>
        <div
          className={clsx(
            "SiteFooter__navigation",
            "lg:w-4/12",
            "px-8 lg:py-16",
          )}
        >
          <_NavigationItems />
        </div>

        <div
          className={clsx(
            "SiteFooter__social",
            "lg:w-2/12",
            "px-8 py-8 lg:py-16",
          )}
        >
          <div
            className={clsx(
              "SiteFooter__socialItems",
              "flex items-center space-x-8",
              "py-2",
            )}
          >
            <_SocialItem
              href={"https://twitter.com/weareopenbet"}
              icon={<TwitterIcon />}
              label="Follow us on Twitter"
            ></_SocialItem>

            <_SocialItem
              href={"https://www.linkedin.com/company/weareopenbet/"}
              icon={<LinkedInIcon />}
              label="Connect with us on LinkedIn"
            ></_SocialItem>
          </div>
        </div>

        <div
          className={clsx(
            "SiteFooter__footerNavigation",
            "lg:w-4/12",
            "px-8 py-8 lg:py-16",
          )}
        >
          <_FooterNavigationItems />

          <div className={clsx("SiteFooter__ageNotice")}>
            <div className={clsx("SiteFooter__ageHeader", "text-xl font-bold")}>
              18+
            </div>
            <div className={clsx("SiteFooter__ageContent", "mb-8")}>
              This website is not intended for persons under 18 years of age
            </div>
          </div>

          <div className={clsx("SiteFooter__copyright", "text-sm")}>
            ©{year} OpenBet — All Rights Reserved
          </div>
        </div>
      </div>
    </footer>
  );
};

export default SiteFooter;
