import { AuthProvider } from "@/contexts/AuthContext";
import { HeaderProvider } from "@/contexts/HeaderContext";
import Layout from "@/layouts/Layout";
import "@/styles/app.css";
import "@/styles/globals.css";
import "intersection-observer";
import { Provider as JotaiProvider } from "jotai";
import { NextComponentType, NextPageContext } from "next";
import Head from "next/head";
import { useRouter } from "next/router";
import Script from "next/script";
import NProgress from "nprogress";
import React, { useEffect } from "react";
import TagManager from "react-gtm-module";

NProgress.configure({ easing: "easeOut", speed: 750 });

declare global {
  interface Window {
    dataLayer: any;
  }
}

type AppProps = {
  pageProps: any;
  Component: NextComponentType<NextPageContext, any, {}> & { Layout: any };
};

function MyApp({ Component, pageProps }: AppProps): JSX.Element {
  const TargetLayout = Component.Layout ? Component.Layout : Layout;

  const router = useRouter();

  /*
		Kick off nprogress on route change
	*/
  useEffect(() => {
    const routeChangeStart = () => {
      NProgress.start();
    };

    const routeChangeEnd = () => {
      NProgress.done();
    };

    router.events.on("routeChangeStart", routeChangeStart);
    router.events.on("routeChangeComplete", routeChangeEnd);
    router.events.on("routeChangeError", routeChangeEnd);
  }, [router]);

  /*
		Init tag manager
	*/

  useEffect(() => {
    TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GTM_ID });
  }, []);

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta
          name="viewport"
          content="height=device-height, width=device-width, initial-scale=1.0, minimum-scale=1.0"
        />
        <meta name="format-detection" content="telephone=no" />

        <link rel="preconnect" href="https://weareopenbet.wistia.com/" />

        <meta charSet="utf-8" />

        <link
          rel="sitemap"
          type="application/xml"
          title="Sitemap"
          href="/sitemaps-1-sitemap.xml"
        />

        <link
          rel="apple-touch-icon-precomposed"
          sizes="57x57"
          href="/apple-touch-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="114x114"
          href="/apple-touch-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="72x72"
          href="/apple-touch-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="144x144"
          href="/apple-touch-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="60x60"
          href="/apple-touch-icon-60x60.png"
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="120x120"
          href="/apple-touch-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="76x76"
          href="/apple-touch-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="152x152"
          href="/apple-touch-icon-152x152.png"
        />
        <link
          rel="icon"
          type="image/png"
          href="/favicon-196x196.png"
          sizes="196x196"
        />
        <link
          rel="icon"
          type="image/png"
          href="/favicon-96x96.png"
          sizes="96x96"
        />
        <link
          rel="icon"
          type="image/png"
          href="/favicon-32x32.png"
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href="/favicon-16x16.png"
          sizes="16x16"
        />
        <link
          rel="icon"
          type="image/png"
          href="/favicon-128.png"
          sizes="128x128"
        />
        <meta name="application-name" content="OpenBet" />
        <meta name="msapplication-TileColor" content="#FFFFFF" />
        <meta name="msapplication-TileImage" content="mstile-144x144.png" />
        <meta name="msapplication-square70x70logo" content="mstile-70x70.png" />
        <meta
          name="msapplication-square150x150logo"
          content="mstile-150x150.png"
        />
        <meta
          name="msapplication-wide310x150logo"
          content="mstile-310x150.png"
        />
        <meta
          name="msapplication-square310x310logo"
          content="mstile-310x310.png"
        />

        <meta name="apple-mobile-web-app-title" content="OpenBet" />
        <meta name="application-name" content="OpenBet" />
        <meta name="apple-mobile-web-app-status-bar-style" content="white" />
      </Head>

      <Script
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        type="text/javascript"
        data-domain-script="fef6c344-709c-4107-b3de-b5eadb14020d-test"
        strategy="beforeInteractive"
        onLoad={() => {
          function OptanonWrapper() {}
        }}
      />

      <JotaiProvider>
        <AuthProvider>
          <HeaderProvider>
            <TargetLayout {...pageProps}>
              <Component {...pageProps} />
            </TargetLayout>
          </HeaderProvider>
        </AuthProvider>
      </JotaiProvider>
    </>
  );
}
export default MyApp;
