import { isSiteMobileMenuActiveAtom } from "@/atoms/siteHeader";
import Logotype from "@/components/Logotype";
import Marquee from "@/components/Marquee";
import { useHeaderContext } from "@/contexts/HeaderContext";
import clsx from "clsx";
import { useAtom } from "jotai";
import Link from "next/link";
import { useInView } from "react-intersection-observer";

const SiteHeader = ({
  headerWords = [],
  showEndeavorLogo = false,
}): JSX.Element => {
  const stringifiedWords = JSON.stringify(headerWords);

  const { ref, inView } = useInView({ triggerOnce: true });

  const { isTop, isPinned } = useHeaderContext();

  const [isSiteMobileMenuActive, setIsSiteMobileMenuActive] = useAtom(
    isSiteMobileMenuActiveAtom,
  );

  return (
    <header
      className={clsx("SiteHeader", "fixed left-0 top-0 z-50 ", {})}
      ref={ref}
    >
      <div
        className={clsx(
          "SiteHeader__content",
          "relative mx-auto flex max-w-screen-3xl items-start pt-8 lg:px-16 lg:pr-8 lg:pt-16",
          {
            //  "h-32 lg:h-44 xl:h-64": !showEndeavorLogo,
          },
        )}
      >
        <div className={clsx("SiteHeader__wrapper")}>
          <Link
            href="/"
            className={clsx(
              "SiteHeader__logotype",
              "block w-52 px-8 lg:w-64 lg:px-0 xl:w-80",
              "transition-all duration-500 ease-out",
              {
                "translate-y-0 opacity-100": isTop,
                "-translate-y-full opacity-0":
                  !isTop && !isSiteMobileMenuActive,
                "text-white": isSiteMobileMenuActive,
              },
            )}
            aria-label="OpenBet"
          >
            <Logotype />
          </Link>

          {!showEndeavorLogo && headerWords.length > 0 && (
            <div
              className={clsx(
                "SiteHeader__marquee",
                "relative -mt-1 px-8 lg:px-0",
                "transition-all duration-500 ease-out",
                {
                  hidden: isSiteMobileMenuActive,
                  "translate-y-0 opacity-100": isTop,
                  "-translate-y-full opacity-0":
                    !isTop && !isSiteMobileMenuActive,
                  "text-white": isSiteMobileMenuActive,
                },
              )}
            >
              <Marquee key={stringifiedWords} words={headerWords} />
            </div>
          )}

          {showEndeavorLogo && (
            <Link
              href="https://www.endeavorco.com"
              target="_blank"
              className={clsx(
                "SiteHeader__endeavorWrapper",
                "relative mt-1 block px-8 lg:px-0",
                "transform transition duration-500 ease-out",
                {
                  "translate-y-0 opacity-100": isTop,
                  "-translate-y-full opacity-0": !isTop,
                  hidden: isSiteMobileMenuActive,
                },
              )}
            >
              <span className={clsx("text-2xl tracking-tight")}>
                An Endeavor Company
              </span>
            </Link>
          )}
        </div>
      </div>
    </header>
  );
};

export default SiteHeader;
