export function getHref(element: {
  groupHandle?: string;
  sectionHandle?: string;
  slug?: string;
  uri?: string;
}): string {
  if (!element) return `/`;

  const handle = element.sectionHandle || element.groupHandle;

  switch (handle) {
    case "newsIndex":
      return `/news`;
    case "news":
      return `/news/${element.slug}`;

    case "insightsIndex":
      return `/insights`;
    case "insights":
      return `/insights/${element.slug}`;

    case "teamIndex":
      return `/team`;
    case "profiles":
      return `/team/${element.slug}`;
    case "profileTypes":
      return `/team/category/${element.slug}`;

    case "contact":
      return `/contact`;

    case "careersIndex":
      return `/careers`;

    case "pages":
      return `/${element.uri}`;

    case "portalIndex":
      return `/portal`;

    default:
      return `/`;
  }
}

export default getHref;
