import LinkedInIcon from "@/components/LinkedInIcon";
import TwitterIcon from "@/components/TwitterIcon";
import { useHeaderContext } from "@/contexts/HeaderContext";
import { getHref } from "@/lib/routes";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import clsx from "clsx";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { ArrowRight } from "react-feather";

const PrimaryNavigation = ({ items = [] }): JSX.Element => {
  const { isPinned, isTop } = useHeaderContext();

  const [value, setValue] = useState("");

  const router = useRouter();

  const _SocialItem = ({ href, icon, label = "" }) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noreferrer"
        className={clsx(
          "PrimaryNavigation__item--social",
          "flex items-center justify-center",
          "h-5 w-5",
          "transition-opacity",
          "hover:opacity-60",
        )}
        aria-label={label}
      >
        {icon}
      </a>
    );
  };

  const parentItems = items.filter((i) => i.level === 1);

  useEffect(() => {
    // Function to handle route change
    const handleRouteChange = () => {
      setValue(""); // Reset value to empty string
    };

    // Add route change event listener
    router.events.on("routeChangeComplete", handleRouteChange);

    // Remove event listener on component unmount
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]); // Depend on router.events to re-run effect if it changes

  return (
    <nav
      className={clsx(
        "PrimaryNavigation",
        "fixed inset-x-0 top-0 z-40 hidden transition-all duration-500 ease-out lg:block",
        {
          "translate-y-0 opacity-100": isPinned || isTop,
          "-translate-y-32 opacity-0": !isPinned && !isTop,
        },
      )}
    >
      <div
        className={clsx(
          "PrimaryNavigation__content",
          "mx-auto flex items-center justify-end space-x-4 px-16 py-16 transition-all ease-out",
          {
            "py-8": isPinned && !isTop,
          },
        )}
      >
        <NavigationMenu.Root
          className={clsx("PrimaryNavigation__navigationRoot")}
          value={value}
          onValueChange={setValue}
        >
          <NavigationMenu.List
            className={clsx(
              "PrimaryNavigation_navigationItems",
              "relative z-30 ml-auto flex items-center space-x-4",
            )}
          >
            {parentItems.map((item) => {
              return (
                <_NavigationMenuItem
                  key={item.id}
                  item={item}
                  allItems={items}
                ></_NavigationMenuItem>
              );
            })}
          </NavigationMenu.List>

          <NavigationMenu.Viewport
            className={clsx(
              "PrimaryNavigation_navigationViewport",
              "relative z-20",
            )}
          />
        </NavigationMenu.Root>

        <div
          className={clsx(
            "PrimaryNavigation__socialItems",
            "pointer-events-auto flex space-x-4",
          )}
        >
          <_SocialItem
            href={"https://x.com/weareopenbet"}
            icon={
              <svg
                role="img"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>X</title>
                <path d="M18.901 1.153h3.68l-8.04 9.19L24 22.846h-7.406l-5.8-7.584-6.638 7.584H.474l8.6-9.83L0 1.154h7.594l5.243 6.932ZM17.61 20.644h2.039L6.486 3.24H4.298Z" />
              </svg>
            }
            label="Follow us on Twitter"
          ></_SocialItem>

          <_SocialItem
            href={"https://www.linkedin.com/company/weareopenbet/"}
            icon={
              <svg
                role="img"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>LinkedIn</title>
                <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z" />
              </svg>
            }
            label="Connect with us on LinkedIn"
          ></_SocialItem>
        </div>
      </div>
    </nav>
  );
};

const _NavigationMenuItem = ({ item, allItems }) => {
  const router = useRouter();

  const childItems = allItems.filter((i) => i.parent?.id === item.id);

  const href = item.element ? getHref(item.element) : item.url;

  const target = item.newWidow ? "_blank" : null;

  if (!href) return null;

  // Work out whether childItems has further children
  const hasGrandchildren = childItems.some((i) =>
    allItems.some((j) => j.parent?.id === i.id),
  );

  if (childItems.length > 0) {
    return (
      <NavigationMenu.Item
        className={clsx(
          "PrimaryNavigation_navigationItem PrimaryNavigation_navigationItem--withChildren",
          "relative z-40",
        )}
        value={item.id}
      >
        <NavigationMenu.Trigger
          className={clsx(
            "PrimaryNavigation_navigationItemTrigger",
            "group relative flex items-center space-x-1",
          )}
          onClick={(e) => {
            e.preventDefault();
            router.push(href);
          }}
        >
          <div
            className={clsx(
              "PrimaryNavigation_navigationItemTriggerContent",
              "group-hover-underline relative whitespace-nowrap font-bold",
            )}
          >
            {item.title}
          </div>
        </NavigationMenu.Trigger>
        {/* If the item has grandchildren, then show megamenu */}
        {hasGrandchildren && (
          <NavigationMenu.Content
            className={clsx(
              "PrimaryNavigation_navigationItemContent",
              "fixed inset-x-0 top-0 z-10 bg-white-dark shadow-2xl",
            )}
          >
            <_NavigationItemContent
              childItems={childItems}
              allItems={allItems}
            />
          </NavigationMenu.Content>
        )}
        {/* Otherwise show a simple dropdown */}
        {!hasGrandchildren && (
          <NavigationMenu.Content
            className={clsx(
              "PrimaryNavigation_navigationItemContent",
              "absolute inset-x-0 top-0 z-10 p-4",
            )}
          >
            <NavigationMenu.Sub
              className={clsx(
                "PrimaryNavigation_navigationItemSub",
                "rounded bg-white-dark p-8 shadow-sm",
              )}
              orientation="vertical"
            >
              <NavigationMenu.List
                className={clsx(
                  "PrimaryNavigation_navigationItemSubList",
                  "space-y-3",
                )}
              >
                {childItems.map((childItem) => {
                  const childHref = childItem.element
                    ? getHref(childItem.element)
                    : childItem.url;

                  return (
                    <NavigationMenu.Item
                      key={childItem.id}
                      value={childItem.id}
                      className={clsx(
                        "PrimaryNavigation_navigationItemSubListItem",
                      )}
                    >
                      <_NavigationMenuLink
                        href={childHref}
                        target={childItem.newWidow ? "_blank" : null}
                        className={clsx(
                          "PrimaryNavigation_navigationItemSubListItemLink",
                          "group relative flex items-center space-x-1 data-[state=open]:text-pink",
                        )}
                      >
                        <div
                          className={clsx(
                            "PrimaryNavigation_navigationItemSubListItemContent",
                            "group-hover-underline relative whitespace-nowrap font-bold",
                          )}
                        >
                          {childItem.title}
                        </div>
                      </_NavigationMenuLink>
                    </NavigationMenu.Item>
                  );
                })}
              </NavigationMenu.List>
              <NavigationMenu.Viewport className={clsx("!col-span-9")} />
            </NavigationMenu.Sub>
          </NavigationMenu.Content>
        )}
      </NavigationMenu.Item>
    );
  }

  return (
    <NavigationMenu.Item
      className={clsx(
        "PrimaryNavigation_navigationItem PrimaryNavigation_navigationItem--withoutChildren",
        "relative z-40",
      )}
    >
      <_NavigationMenuLink
        href={href}
        target={target}
        className={clsx(
          "PrimaryNavigation_navigationItemLink",
          "group relative flex items-center space-x-1",
        )}
      >
        <div
          className={clsx(
            "PrimaryNavigation_navigationItemLinkContent",
            "group-hover-underline relative whitespace-nowrap font-bold",
          )}
        >
          {item.title}
        </div>
      </_NavigationMenuLink>
    </NavigationMenu.Item>
  );
};

const _NavigationMenuLink = ({ href, ...props }) => {
  const router = useRouter();
  const isActive = router.asPath === href;

  return (
    <Link href={href} passHref legacyBehavior>
      <NavigationMenu.Link
        className="PrimaryNavigation_navigationLink"
        active={isActive}
        {...props}
      />
    </Link>
  );
};

const _NavigationItemContent = ({ childItems, allItems }) => {
  const router = useRouter();

  const [value, setValue] = useState(childItems[0]?.id);

  return (
    <div
      className={clsx(
        "PrimaryNavigation_navigationItemContent",
        "px-16 pb-16 pt-56",
      )}
    >
      <NavigationMenu.Sub
        className={clsx(
          "PrimaryNavigation_navigationItemSub",
          "grid grid-cols-12 *:col-span-3",
        )}
        orientation="vertical"
        onValueChange={setValue}
        value={value}
      >
        <NavigationMenu.List
          className={clsx(
            "PrimaryNavigation_navigationItemSubList",
            "space-y-6",
          )}
        >
          {childItems.map((childItem) => {
            const childHref = childItem.element
              ? getHref(childItem.element)
              : childItem.url;

            const grandChildItems = allItems.filter(
              (i) => i.parent?.id === childItem?.id,
            );

            return (
              <NavigationMenu.Item
                key={childItem.id}
                value={childItem.id}
                className={clsx("PrimaryNavigation_navigationItemSubListItem")}
              >
                <NavigationMenu.Trigger
                  className={clsx(
                    "PrimaryNavigation_navigationItemSubListItemTrigger",
                    "data-[state=closed]:closed group relative inline-block text-4xl font-bold text-gray data-[state=open]:text-navy",
                    "after:duration-750 after:absolute after:inset-x-0 after:-bottom-2 after:block after:h-2 after:origin-left after:bg-pink after:transition after:ease-in-out after:content-[''] data-[state=closed]:after:scale-x-0 data-[state=open]:after:scale-x-100",
                  )}
                  onClick={(e) => {
                    e.preventDefault();
                    router.push(childHref);
                  }}
                >
                  <span>{childItem.title}</span>
                </NavigationMenu.Trigger>
                <NavigationMenu.Content
                  className={clsx(
                    "PrimaryNavigation_navigationGrandchildrenList",
                    "space-y-8",
                  )}
                >
                  <div className={clsx("text-4xl font-bold")}>
                    {childItem.element?.strapline || childItem.title}
                  </div>
                  <NavigationMenu.Sub
                    className={clsx(
                      "PrimaryNavigation_navigationGrandchildren",
                    )}
                  >
                    <NavigationMenu.List
                      className={clsx(
                        "PrimaryNavigation_navigationGrandchildrenList",
                        "group grid grid-cols-2 gap-8",
                      )}
                    >
                      {grandChildItems.map((grandChildItem) => {
                        const grandChildHref = grandChildItem.element
                          ? getHref(grandChildItem.element)
                          : grandChildItem.url;

                        return (
                          <_GrandChildLink
                            key={grandChildItem.id}
                            heading={grandChildItem.title}
                            headline={grandChildItem.element?.headline}
                            href={grandChildHref}
                          />
                        );
                      })}
                      {grandChildItems?.length === 0 && (
                        <_GrandChildLink
                          heading={childItem.element?.headline}
                          href={childHref}
                        />
                      )}
                    </NavigationMenu.List>
                  </NavigationMenu.Sub>
                </NavigationMenu.Content>
              </NavigationMenu.Item>
            );
          })}
        </NavigationMenu.List>
        <NavigationMenu.Viewport className={clsx("!col-span-9 ")} />
      </NavigationMenu.Sub>
    </div>
  );
};

const _GrandChildLink = ({ headline = "", heading, href }) => {
  return (
    <Link
      href={href}
      className={clsx(
        "PrimaryNavigation_navigationGrandchildLink",
        "group/grandchild col-span-1 transition ease-out hover:!opacity-100 group-hover:opacity-50",
      )}
    >
      <div
        className={clsx(
          "PrimaryNavigation_navigationGrandchildContent",
          "space-y-4",
        )}
      >
        <div>
          <div className={clsx("text-3xl font-bold text-pink")}>{heading}</div>

          <div className={clsx("text-3xl font-bold")}>{headline}</div>
        </div>

        <div className={clsx("flex items-center space-x-2")}>
          <div className={clsx("text-xl font-bold")}>Find out more</div>
          <div
            className={clsx(
              "transition ease-out group-hover/grandchild:translate-x-1",
            )}
          >
            <ArrowRight className={clsx("h-5 w-5")} />
          </div>
        </div>
      </div>
    </Link>
  );
};

export default PrimaryNavigation;
