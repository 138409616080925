import { isSiteMobileMenuActiveAtom } from "@/atoms/siteHeader";
import { getHref } from "@/lib/routes";
import * as Accordion from "@radix-ui/react-accordion";
import * as Dialog from "@radix-ui/react-dialog";
import clsx from "clsx";
import { useAtom } from "jotai";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import { ChevronDown, ChevronUp } from "react-feather";

const SiteMobileMenu = ({ items }) => {
  const router = useRouter();

  const [isSiteMobileMenuActive, setIsSiteMobileMenuActive] = useAtom(
    isSiteMobileMenuActiveAtom,
  );

  /*
		Close menu when route changes
	*/
  useEffect(() => {
    const routeChangeStart = () => {
      setIsSiteMobileMenuActive(false);
    };

    router.events.on("routeChangeStart", routeChangeStart);
    router.events.on("routeChangeError", routeChangeStart);
  }, [router, setIsSiteMobileMenuActive]);

  const parentItems = items?.filter((i) => i.level === 1) || [];

  return (
    <Dialog.Root
      open={isSiteMobileMenuActive}
      onOpenChange={setIsSiteMobileMenuActive}
      modal={true}
    >
      <Dialog.Trigger asChild>
        <button
          className={clsx(
            "MobileMenuToggle",
            "fixed right-0 top-0 z-50 block flex h-24 w-28 items-center justify-center lg:hidden",
          )}
        >
          <svg
            width="40"
            height="14"
            viewBox="0 0 40 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 1H40" stroke="currentColor" strokeWidth="1.5" />
            <path d="M0 7H40" stroke="currentColor" strokeWidth="1.5" />
            <path d="M14 13L40 13" stroke="currentColor" strokeWidth="1.5" />
          </svg>
        </button>
      </Dialog.Trigger>
      <Dialog.Content className={clsx("SiteMobileMenu", "lg:hidden")}>
        <div
          className={clsx(
            "SiteMobileMenu__content",
            "fixed inset-0 inset-0 z-40 flex flex-col bg-gradient-to-br from-navy via-navy to-blue",
          )}
        >
          <Accordion.Root
            type="multiple"
            className={clsx(
              "SiteMobileMenu__items",
              "overflow-y-auto px-8 py-32 text-white lg:p-16",
            )}
            collapsible
          >
            {parentItems.map((item) => {
              const href = item.element ? getHref(item.element) : item.url;

              const target = !item.element ? "_blank" : null;

              return (
                <Accordion.Item
                  key={item.id}
                  className={clsx(
                    "SiteMobileMenu__parentItem",
                    "flex flex-col py-2",
                  )}
                  value={item.id}
                >
                  <Accordion.Header
                    className={clsx(
                      "SiteMobileMenu__parentItemHeader",
                      "group flex items-center ",
                    )}
                  >
                    <Link
                      href={href}
                      className={clsx(
                        "SiteMobileMenu__parentItemLink",
                        "text-4xl font-bold tracking-tight",
                      )}
                      target={target}
                    >
                      {item.title}
                    </Link>
                    {item.children.length > 0 && (
                      <Accordion.Trigger
                        className={clsx(
                          "SiteMobileMenu__parentItemTrigger",
                          "ml-auto flex h-12 w-12 items-center justify-center",
                        )}
                      >
                        <ChevronDown
                          className={clsx(
                            "h-6 w-6 group-data-[state=open]:hidden",
                          )}
                        />
                        <ChevronUp
                          className={clsx(
                            "h-6 w-6 group-data-[state=closed]:hidden",
                          )}
                        />
                      </Accordion.Trigger>
                    )}
                  </Accordion.Header>
                  {item.children.length > 0 && (
                    <Accordion.Content
                      className={clsx(
                        "SiteMobileMenu__parentItemContent",
                        "flex flex-col data-[state=open]:py-4",
                      )}
                    >
                      {item.children.map((child) => {
                        const href = child.element
                          ? getHref(child.element)
                          : child.url;

                        const target = !child.element ? "_blank" : null;

                        return (
                          <Accordion.Item
                            key={child.id}
                            value={child.id}
                            className={clsx(
                              "SiteMobileMenu__childItem",
                              "pl-4",
                            )}
                          >
                            <Accordion.Header
                              key={child.id}
                              className={clsx(
                                "SiteMobileMenu__childItemHeader",
                                "group flex h-12 items-center",
                              )}
                            >
                              <Link
                                href={href}
                                className={clsx(
                                  "SiteMobileMenu__childItemLink",
                                  "text-3xl font-bold tracking-tight",
                                )}
                                target={target}
                              >
                                {child.title}
                              </Link>
                              {child.children.length > 0 && (
                                <Accordion.Trigger
                                  className={clsx(
                                    "SiteMobileMenu__childItemTrigger",
                                    "ml-auto flex h-12 w-12 items-center justify-center",
                                  )}
                                >
                                  <ChevronDown
                                    className={clsx(
                                      "h-6 w-6 group-data-[state=open]:hidden",
                                    )}
                                  />
                                  <ChevronUp
                                    className={clsx(
                                      "h-6 w-6 group-data-[state=closed]:hidden",
                                    )}
                                  />
                                </Accordion.Trigger>
                              )}
                            </Accordion.Header>
                            {child.children.length > 0 && (
                              <Accordion.Content
                                className={clsx(
                                  "SiteMobileMenu__childItemContent",
                                  "py-4",
                                )}
                              >
                                <Accordion.Root
                                  type="multiple"
                                  className={clsx(
                                    "SiteMobileMenu__childItemRoot",
                                    "space-y-2 px-4",
                                  )}
                                >
                                  {child.children.map((grandchild) => {
                                    const href = grandchild.element
                                      ? getHref(grandchild.element)
                                      : grandchild.url;

                                    const target = !grandchild.element
                                      ? "_blank"
                                      : null;

                                    return (
                                      <Accordion.Item
                                        key={grandchild.id}
                                        value={grandchild.id}
                                        className={clsx(
                                          "SiteMobileMenu__grandchildItem",
                                          "flex flex-col",
                                        )}
                                      >
                                        <Accordion.Header
                                          key={grandchild.id}
                                          className={clsx(
                                            "SiteMobileMenu__grandchildHeader",
                                            "group flex flex-col",
                                          )}
                                        >
                                          <Link
                                            href={href}
                                            className={clsx(
                                              "SiteMobileMenu__grandchildLink",
                                              "text-xl font-bold tracking-tight text-pink",
                                            )}
                                            target={target}
                                          >
                                            {grandchild.title}
                                          </Link>
                                        </Accordion.Header>
                                      </Accordion.Item>
                                    );
                                  })}
                                </Accordion.Root>
                              </Accordion.Content>
                            )}
                          </Accordion.Item>
                        );
                      })}
                    </Accordion.Content>
                  )}
                </Accordion.Item>
              );
            })}
          </Accordion.Root>
        </div>
        <Dialog.Close asChild>
          <button
            className={clsx(
              "SiteMobileMenu__close",
              "fixed right-0 top-0 z-50 block flex h-24 w-28 items-center justify-center lg:hidden",
            )}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="3"
                y="20"
                width="24"
                height="2"
                transform="rotate(-45 3 20)"
                fill="#F7F7F7"
              />
              <rect
                x="4"
                y="3"
                width="24"
                height="2"
                transform="rotate(45 4 3)"
                fill="#F7F7F7"
              />
            </svg>
          </button>
        </Dialog.Close>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default SiteMobileMenu;
