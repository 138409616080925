import Head from "next/head";

const LayoutHead = () => {
  return (
    <Head>
      <meta charSet="utf-8" />
      <meta httpEquiv="x-ua-compatible" content="ie=edge" />
      <meta
        name="viewport"
        content="height=device-height, width=device-width, initial-scale=1.0, minimum-scale=1.0"
      />
      <meta name="format-detection" content="telephone=no" />

      <meta charSet="utf-8" />

      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />

      <meta name="apple-mobile-web-app-title" content="OpenBet" />
      <meta name="application-name" content="OpenBet" />
      <meta name="apple-mobile-web-app-status-bar-style" content="black" />
      <meta name="format-detection" content="telephone=no" />
      <meta name="msapplication-TileColor" content="#000000" />
      <meta name="theme-color" content="#000000" />
    </Head>
  );
};

export default LayoutHead;
